export const personalData = [
  {
    label: "Name: ",
    value: "Qujuan Miller",
  },
  {
    label: "Age: ",
    value: "37",
  },
  {
    label: "Address: ",
    value: "Valdosta, Ga",
  },
  {
    label: "Email: ",
    value: "qujuan.miller@snhu.edu",
  },
  {
    label: "Phone Number: ",
    value: "229-757-1502",
  },
  {
    label: "Military Status: ",
    value: "Navy Veteran",
  },
];
